import React from "react"

import Layout from "../components/layout"
import CompanyInfo from '../components/companyInformation';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../components/style.css';

const CompanyInformation = () => (
  <Layout>
    <CompanyInfo />
  </Layout>
)

export default CompanyInformation
