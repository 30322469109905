import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import windowSize from './windowSize';

function Business({windowWidth}) {
    return (
        <>
            <div className="SubBanner" style={getImageUrl(windowWidth)}>
                <div className="subBanner-title">COMPANY</div>
            </div>
            <Container>
                <div className="subContent">
                    <div className="mission">
                        <div className="subContent-title">MISSION</div>
                        <div className="subContent-description">
                            Imagine and create games to be proud of, and share it with the world to inspire.<br />
                            자유롭게 상상하고, 자랑스러운 게임을 만들어, 이 세상과 함께 나누자
                        </div>
                    </div>
                    <div className="vision">
                        <div className="subContent-title">VISION</div>
                        <div className="subContent-description">
                            We want to offer new and diverse experience in gaming and technology.<br />
                            게임과 기술로 새롭고 다양한 경험을 제공하자
                        </div>
                    </div>
                    <div className="main-value">
                        <div className="subContent-title">Five Core Values</div>
                        <div className="subContent-description">
                            <ul>
                                <li>Users opinions matter, think what I think is the best for the users</li>
                                <li>Trust others and do best to earn trust</li>
                                <li>Deliver results</li>
                                <li>There is always better way than now</li>
                                <li>Simplicity over complexity</li>
                            </ul>
                        </div>
                    </div>
                    <div className="history">
                        <div className="subContent-title">HISTORY</div>
                        <div className="subContent-description">
                            <Row>
                                <Col className="year" sm={12} xs={12}>2019</Col>
                            </Row>
                            <Row className="history-row">
                                <Col className="date" sm={1} xs={12}>11.12.</Col>
                                <Col sm={11} xs={12} className="detail">
                                    특허 제 10-2040509호 등록
                                </Col>
                            </Row>
                            <Row className="history-row">
                                <Col className="date" sm={1} xs={12}>09.17.</Col>
                                <Col sm={11} xs={12} className="detail">
                                    기술평가 우수기업 인증 T-5 (나이스(Nice) 평가정보)
                                </Col>
                            </Row>
                            <Row>
                                <Col className="last year" sm={12} xs={12}>2018</Col>
                            </Row>
                            <Row className="history-row">
                                <Col className="date" sm={1} xs={12}>11.30.</Col>
                                <Col sm={11} xs={12} className="detail">벤처기업 확인</Col>
                            </Row>
                            <Row className="history-row">
                                <Col className="date" sm={1} xs={12}>11.24.</Col>
                                <Col sm={11} xs={12} className="detail">Fair Play Alliance 협회 가입 승인 (Fair Play Alliance)</Col>
                            </Row>
                            <Row className="history-row">
                                <Col className="date" sm={1} xs={12}>11.14.</Col>
                                <Col sm={11} xs={12} className="detail">모바일 소셜 어플리케이션 '쿠나' 구글플레이 출시 ((주)함께하자쿠나)</Col>
                            </Row>
                            <Row className="history-row">
                                <Col className="date" sm={1} xs={12}>11.01.</Col>
                                <Col sm={11} xs={12} className="detail">기술혁신형 창업기업 지원사업 선정</Col>
                            </Row>
                            <Row className="history-row">
                                <Col className="date" sm={1} xs={12}>08.16.</Col>
                                <Col sm={11} xs={12} className="detail">기술평가 우수기업 인증 T-5 (나이스(Nice) 평가정보)</Col>
                            </Row>
                            <Row className="history-row">
                                <Col className="date" sm={1} xs={12}>07.19.</Col>
                                <Col sm={11} xs={12} className="detail">기업부설연구소 인정 (한국산업기술진흥협회)</Col>
                            </Row>
                            <Row className="history-row">
                                <Col className="date" sm={1} xs={12}>06.06.</Col>
                                <Col sm={11} xs={12} className="detail">주식회사 나노컴퍼니 설립 (자본금 50,000,000원)</Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
}

const getImageUrl = windowWidth => (
    windowWidth >= 576 ? 
    {backgroundImage: 'url(/image/img_sub_company.png)'}
    : {
        backgroundImage: 'url(/mobile/img_sub_company.png)',
        backgroundSize: `${windowWidth}px`,
        backgroundRepeat: 'no-repeat',
    });
  
export default windowSize(Business);